import React, { useState } from 'react';
import classNames from 'classnames';
import arrayMutators from 'final-form-arrays';
import { compose } from 'redux';
import { Field, Form as FinalForm } from 'react-final-form';

import * as validators from '../../../util/validators';
import { FormattedMessage, injectIntl } from '../../../util/reactIntl';

import {
  Form,
  IconSpinner,
  PrimaryButton,
  FieldTextInput,
  FieldPhoneNumberInputWithDialCode,
} from '../../../components';

import css from './SearchRequirementsForm.module.css';
import { ReactComponent as PDFIconSVG } from '../../../assets/svg/pdfIcon.svg';

const MAX_IMAGE_SIZE = 25;

const CloseBtnIcon = () => (
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 10 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g strokeWidth="1" fillRule="evenodd">
      <g transform="translate(-821.000000, -311.000000)">
        <g transform="translate(809.000000, 299.000000)">
          <path
            d="M21.5833333,16.5833333 L17.4166667,16.5833333 L17.4166667,12.4170833 C17.4166667,12.1866667 17.2391667,12 17.00875,12 C16.77875,12 16.5920833,12.18625 16.5920833,12.41625 L16.5883333,16.5833333 L12.4166667,16.5833333 C12.18625,16.5833333 12,16.7695833 12,17 C12,17.23 12.18625,17.4166667 12.4166667,17.4166667 L16.5875,17.4166667 L16.5833333,21.5829167 C16.5829167,21.8129167 16.7691667,21.9995833 16.9991667,22 L16.9995833,22 C17.2295833,22 17.41625,21.81375 17.4166667,21.58375 L17.4166667,17.4166667 L21.5833333,17.4166667 C21.8133333,17.4166667 22,17.23 22,17 C22,16.7695833 21.8133333,16.5833333 21.5833333,16.5833333"
            transform="translate(17.000000, 17.000000) rotate(-45.000000) translate(-17.000000, -17.000000) "
          />
        </g>
      </g>
    </g>
  </svg>
);

const SearchRequirementsFormComponent = props => {
  const [maxSizeMessage, setMaxSizeMessage] = useState('');
  const { contactLoading } = props;

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          rootClassName,
          intl,
          form,
          ready,
          formId,
          values,
          invalid,
          handleSubmit,
        } = formRenderProps;
        const submitReady = ready;
        const submitDisabled = invalid;
        const classes = classNames(rootClassName || css.root, className);

        // email
        const emailRequired = validators.required(
          intl.formatMessage({
            id: 'SignupForm.emailRequired',
          })
        );
        const emailInvalid = validators.emailFormatValid(
          intl.formatMessage({
            id: 'SignupForm.emailInvalid',
          })
        );
        const emailValidators = validators.composeValidators(emailRequired, emailInvalid);

        // phoneNumber
        const phoneNumberRequired = validators.phoneRequired(
          intl.formatMessage({
            id: 'SignupForm.phoneNumberRequired',
          })
        );
        const phoneNumberInvalid = validators.phoneNumberFormatValid(
          intl.formatMessage({
            id: 'SignupForm.phoneNumberInvalid',
          })
        );
        const phoneNumberValidators = validators.composeValidators(
          phoneNumberRequired,
          phoneNumberInvalid
        );

        const setMaxSizeErrorMessage = message => {
          setMaxSizeMessage(message);
        };

        const filterOut = fileName => {
          form.change('additionalDocuments', []);
          const filteredFile = values?.additionalDocuments?.filter(file => file.name !== fileName);

          filteredFile?.forEach(file => {
            form.mutators.push('additionalDocuments', file);
          });
        };

        const handleFilePreview = file => {
          return (
            <div className={css.fileBox}>
              <div className={css.fileInformation}>
                <PDFIconSVG />
                <div>
                  <p title={file.name} className={css.fileName}>
                    {file.name}
                  </p>
                  <p className={css.fileSize}>{(+file.size / 1000).toFixed()} KB</p>
                </div>
              </div>
            </div>
          );
        };

        const onUploadSingleDocument = (file, form) => {
          if (file) {
            const fileSize = (file.size || 0) / (1024 * 1024);

            if (fileSize > MAX_IMAGE_SIZE) {
              setMaxSizeErrorMessage('File size limit is maximum 25 MB.');
            } else {
              setMaxSizeErrorMessage('');
              form.mutators.unshift('additionalDocuments', file);
            }
          }
        };

        return (
          <Form
            className={classes}
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
              form.restart();
            }}
          >
            <div className={css.container}>
              <div className={css.signupFormContainer}>
                <h2>Tell Us Your Requirements</h2>

                <div className={classNames(css.name)}>
                  <FieldTextInput
                    type="text"
                    id={formId ? `${formId}.name` : 'name'}
                    name="name"
                    autoComplete="given-name"
                    label="Product / Service Name"
                    placeholder="Enter product or service name"
                    validate={validators.required('Product or Service name is required')}
                  />
                </div>

                <div className={css.email}>
                  <FieldTextInput
                    type="email"
                    id={formId ? `${formId}.email` : 'email'}
                    name="email"
                    autoComplete="email"
                    label={intl.formatMessage({
                      id: 'SignupForm.emailLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.emailPlaceholder',
                    })}
                    validate={emailValidators}
                  />
                </div>

                <div>
                  <FieldPhoneNumberInputWithDialCode
                    name="phoneNumber"
                    className={css.phoneRequired}
                    id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                    label={intl.formatMessage({
                      id: 'SignupForm.phoneNumberLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'SignupForm.phoneNumberPlaceholder',
                    })}
                    validate={phoneNumberValidators}
                  />
                </div>

                <div>
                  <FieldTextInput
                    type="textarea"
                    id={formId ? `${formId}.requirements` : 'requirements'}
                    name="requirements"
                    autoComplete="on"
                    label="Mention The Requirements"
                    placeholder="Enter your requirements here..."
                  />
                </div>

                <div className={css.mediaContainer}>
                  <div>
                    {maxSizeMessage && <div className={css.maxFileSizeError}>{maxSizeMessage}</div>}

                    <Field
                      type="file"
                      component="input"
                      name="fileUpload"
                      className={css.addDocumnetsInput}
                      accept=".csv, .doc, .jpg, .mp3, .mp4, .ogg, .pdf, .png, .txt, .docx, .jpeg, .webp, .xlsx"
                      onChange={e => {
                        const file = e.target.files[0];
                        onUploadSingleDocument(file, form); // Add to form state
                      }}
                    />
                  </div>

                  {values?.additionalDocuments?.length
                    ? values?.additionalDocuments?.map((file, i) => (
                        <div key={i} className={css.previewDocumentWrapper}>
                          {handleFilePreview(file)}
                          <button
                            type="button"
                            className={css.removeDocument}
                            onClick={() => filterOut(file.name)}
                          >
                            <CloseBtnIcon />
                          </button>
                        </div>
                      ))
                    : null}
                </div>

                <div className={css.bottomWrapper}>
                  <PrimaryButton
                    type="submit"
                    ready={submitReady}
                    disabled={submitDisabled}
                    className={css.signupButton}
                  >
                    {contactLoading ? (
                      <IconSpinner rootClassName={css.loadingIcon} />
                    ) : (
                      <FormattedMessage id="SearchRequirementsForm.submitButtonText" />
                    )}
                  </PrimaryButton>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

const SearchRequirementsForm = compose(injectIntl)(SearchRequirementsFormComponent);

export default SearchRequirementsForm;
