import React, { useState } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { array, bool, node, object, string } from 'prop-types';

import { apiBaseUrl } from '../../../util/api';
import { propTypes } from '../../../util/types';
import { FormattedMessage } from '../../../util/reactIntl';

import { H3, H4, Modal, IconSpinner, ListingCardNew, PaginationLinks } from '../../../components';

import SearchRequirementsForm from '../SearchRequirementsForm/SearchRequirementsForm';

import css from './SearchResultsPanelCustom.module.css';

const SearchResultsPanelCustom = props => {
  const [contactLoading, setContactLoading] = useState(false);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false);
  const {
    className,
    rootClassName,
    search,
    listings,
    pagination,
    currentUser,
    hasNoResult,
    isMapVariant,
    isNewSearchInProgress,
    onManageDisableScrolling,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        pageName="SearchPage"
        pagination={pagination}
        pageSearchParams={search}
        className={css.pagination}
      />
    ) : null;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;

      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;

      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  const handleSubmitRequirements = values => {
    setContactLoading(true);

    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('email', values?.email);
    formData.append('requirements', values?.requirements);
    formData.append('phoneNumber', values?.phoneNumber?.number);
    formData.append('phoneDialCode', values?.phoneNumber?.dialCode);

    // Append all files to the formData object
    for (let i = 0; i < values.additionalDocuments?.length; i++) {
      formData.append('files', values.additionalDocuments?.[i]);
    }

    axios
      .post(`${apiBaseUrl()}/api/sendRequirementsMail`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        // console.log({ res });
        setContactLoading(false);
        setIsSubmissionSuccessful(true);
      })
      .catch(err => {
        console.error({ err });
        setContactLoading(false);
      });
  };

  return (
    <div className={classes}>
      <section className={css.searchPageContent}>
        <div className={css.listingPanel}>
          {isNewSearchInProgress ? (
            <React.Fragment>
              {/* <H3 className={css.header}>
                <FormattedMessage id="SearchPage.loadingListingsTitle" />
              </H3> */}
            </React.Fragment>
          ) : hasNoResult ? (
            <H3 className={css.header}>
              <FormattedMessage id="SearchPage.noResultsTitle" />
            </H3>
          ) : (
            <React.Fragment>
              {/* <H3 className={css.header}>
                <FormattedMessage
                  id="SearchPage.showListingsTitle"
                  values={{
                    count: listings.length,
                  }}
                />
              </H3> */}
            </React.Fragment>
          )}

          {isNewSearchInProgress ? (
            <IconSpinner />
          ) : hasNoResult ? (
            <H4 className={css.noListing}>
              <FormattedMessage id="SearchPage.noResults" />
            </H4>
          ) : (
            <div className={css.listingCards}>
              {listings.map(l => (
                <ListingCardNew
                  listing={l}
                  key={l.id.uuid}
                  author={l.author}
                  currentUser={currentUser}
                  renderSizes={cardRenderSizes(isMapVariant)}
                />
              ))}
            </div>
          )}

          {paginationLinks}
        </div>

        <div className={css.requirementsFormContainer}>
          <SearchRequirementsForm
            contactLoading={contactLoading}
            onSubmit={handleSubmitRequirements}
          />

          <div>
            <Modal
              usePortal
              id="RequirementsSubmissionModal"
              showAsModalMaxWidth={279}
              isOpen={isSubmissionSuccessful}
              onManageDisableScrolling={onManageDisableScrolling}
              onClose={e => {
                e.stopPropagation();
                setIsSubmissionSuccessful(false);
              }}
            >
              <div>
                <H3>Thank You for Your Interest in BizzProfile!</H3>
                <p>
                  We have successfully received your request form. Our team will review your
                  requirements, and we will contact you shortly to discuss the next steps.
                </p>
              </div>
            </Modal>
          </div>
        </div>
      </section>
    </div>
  );
};

SearchResultsPanelCustom.defaultProps = {
  search: null,
  listings: [],
  children: null,
  className: null,
  pagination: null,
  isMapVariant: true,
  rootClassName: null,
};

SearchResultsPanelCustom.propTypes = {
  search: object,
  children: node,
  listings: array,
  className: string,
  isMapVariant: bool,
  rootClassName: string,
  pagination: propTypes.pagination,
};

export default SearchResultsPanelCustom;
