import React, { Component } from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { bool, func, object, node, number, shape, string, arrayOf } from 'prop-types';

import { apiBaseUrl } from '../../../util/api';
import { propTypes } from '../../../util/types';
import { createResourceLocatorString } from '../../../util/routes';
import { FormattedMessage, useIntl, intlShape } from '../../../util/reactIntl';

import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

import {
  H3,
  Modal,
  Button,
  NamedLink,
  ModalInMobile,
  PrimaryButton,
  PrimaryButtonInline,
  SecondaryButtonInline,
} from '../../../components';

import PopupOpenerButton from '../PopupOpenerButton/PopupOpenerButton';
import SearchRequirementsForm from '../SearchRequirementsForm/SearchRequirementsForm';

import css from './SearchFiltersMobile.module.css';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      contactLoading: false,
      initialQueryParams: null,
      isFiltersOpenOnMobile: false,
      isSubmissionSuccessful: false,
    };

    this.resetAll = this.resetAll.bind(this);
    this.openFilters = this.openFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.handleSubmitRequirements = this.handleSubmitRequirements.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({ isFiltersOpenOnMobile: true, initialQueryParams: urlQueryParams });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, routeConfiguration } = this.props;
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });

    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration,
        {},
        this.state.initialQueryParams
      )
    );
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  // Reset all filter query parameters
  resetAll(e) {
    this.props.resetAll(e);
    this.setState(prevState => ({
      ...prevState,
      isFiltersOpenOnMobile: false,
    }));

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  handleSubmitRequirements(values) {
    this.setState(prevState => ({
      ...prevState,
      contactLoading: true,
    }));

    const formData = new FormData();
    formData.append('name', values?.name);
    formData.append('email', values?.email);
    formData.append('requirements', values?.requirements);
    formData.append('phoneNumber', values?.phoneNumber?.number);
    formData.append('phoneDialCode', values?.phoneNumber?.dialCode);

    // Append all files to the formData object
    for (let i = 0; i < values.additionalDocuments?.length; i++) {
      formData.append('files', values.additionalDocuments?.[i]);
    }

    axios
      .post(`${apiBaseUrl()}/api/sendRequirementsMail`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        // console.log({ res });
        this.setState(prevState => ({
          ...prevState,
          contactLoading: false,
          isSubmissionSuccessful: true,
        }));
      })
      .catch(err => {
        console.error({ err });
        this.setState(prevState => ({
          ...prevState,
          contactLoading: false,
        }));
      });
  }

  render() {
    const {
      rootClassName,
      className,
      children,
      sortByComponent,
      listingsAreLoaded,
      resultsCount,
      totalFetchedItems,
      searchInProgress,
      showAsModalMaxWidth,
      onMapIconClick,
      onManageDisableScrolling,
      selectedFiltersCount,
      noResultsInfo,
      isMapVariant,
      intl,
      currentUser,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const isVendor = currentUser?.attributes?.profile?.publicData?.customUserType === 'vendor';

    const resultsFound = (
      <FormattedMessage
        id="SearchFiltersMobile.foundResults"
        values={{ count: resultsCount, totalCount: totalFetchedItems }}
      />
    );
    const noResults = <FormattedMessage id="SearchFiltersMobile.noResults" />;
    const filtersHeading = intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const loadingResults = <FormattedMessage id="SearchFiltersMobile.loadingResults" />;
    const modalCloseButtonMessage = intl.formatMessage({ id: 'SearchFiltersMobile.cancel' });

    const listingButtonLabel = intl.formatMessage({
      id: isVendor
        ? 'SearchFiltersMobile.listYourProductsButtonText'
        : 'SearchFiltersMobile.postForQuotesButtonText',
    });
    const showListingsLabel = intl.formatMessage(
      { id: 'SearchFiltersMobile.showListings' },
      { count: totalFetchedItems }
    );

    return (
      <div className={classes}>
        <div className={css.searchResultSummary}>
          {/* {listingsAreLoaded && resultsCount > 0 ? resultsFound : null}
          {listingsAreLoaded && resultsCount === 0 ? noResults : null} */}
          {searchInProgress ? loadingResults : resultsFound}
        </div>

        <div className={css.buttons}>
          <PopupOpenerButton
            className={css.filterButton}
            toggleOpen={this.openFilters}
            isSelected={selectedFiltersCount > 0}
          >
            <FormattedMessage id="SearchFiltersMobile.filtersButtonLabel" />
          </PopupOpenerButton>

          <SecondaryButtonInline
            onClick={e => {
              this.setState(prevState => ({
                ...prevState,
                isModalOpen: true,
              }));
            }}
            className={css.requirementsFormButton}
          >
            <FormattedMessage id="SearchFiltersMobile.submitRequirementsButtonText" />
          </SecondaryButtonInline>

          {!currentUser ? (
            <div className={css.clickbaitContainer}>
              {!currentUser && (
                <PrimaryButtonInline type="button" className={css.namedLinkButton}>
                  <NamedLink name="SignupAsVendorPage">
                    <FormattedMessage id="SearchFiltersMobile.listYourProductsButtonText" />
                  </NamedLink>
                </PrimaryButtonInline>
              )}
              {!currentUser && (
                <PrimaryButtonInline type="button" className={css.namedLinkButton}>
                  <NamedLink name="SignupAsCustomerPage">
                    <FormattedMessage id="SearchFiltersMobile.postForQuotesButtonText" />
                  </NamedLink>
                </PrimaryButtonInline>
              )}
            </div>
          ) : (
            <div className={css.singlePostButtonContainer}>
              <PrimaryButton type="button" className={css.namedLinkButton}>
                <NamedLink name="NewListingPage">{listingButtonLabel}</NamedLink>
              </PrimaryButton>
            </div>
          )}

          <div className={css.requirementsFormModalContainer}>
            <Modal
              // usePortal
              id="OrderFormInModal"
              showAsModalMaxWidth={279}
              isOpen={this.state.isModalOpen}
              onManageDisableScrolling={onManageDisableScrolling}
              onClose={e => {
                e.stopPropagation();
                this.setState(prevState => ({
                  ...prevState,
                  isModalOpen: false,
                }));
              }}
            >
              <SearchRequirementsForm
                onSubmit={this.handleSubmitRequirements}
                contactLoading={this.state.contactLoading}
              />
            </Modal>
          </div>

          <div>
            <Modal
              usePortal
              id="RequirementsSubmissionModal"
              showAsModalMaxWidth={279}
              isOpen={this.state.isSubmissionSuccessful}
              onManageDisableScrolling={onManageDisableScrolling}
              onClose={e => {
                e.stopPropagation();
                this.setState(prevState => ({
                  ...prevState,
                  isSubmissionSuccessful: false,
                }));
              }}
            >
              <div>
                <H3>Thank You for Your Interest in BizzProfile!</H3>
                <p>
                  We have successfully received your request form. Our team will review your
                  requirements, and we will contact you shortly to discuss the next steps.
                </p>
              </div>
            </Modal>
          </div>

          {/* <Button
            onClick={() => {
              this.setState({ isModalOpen: true });
            }}
          >
            <FormattedMessage id="TopbarDesktop.requirementsForm" />
          </Button>

          <Modal
            usePortal
            showAsModalMaxWidth={279}
            id="searchFilter.requirement"
            isOpen={this.state.isModalOpen}
            onManageDisableScrolling={() => {}}
            onClose={e => {
              e.stopPropagation();
              this.setState({ isModalOpen: false });
            }}
          >
            <SearchRequirementsForm
              onSubmit={handleSubmitRequirements}
              contactLoading={this.state.contactLoading}
            />
          </Modal> */}

          {/* {sortByComponent} */}

          {/* {isMapVariant ? (
            <div className={css.mapIcon} onClick={onMapIconClick}>
              <FormattedMessage id="SearchFiltersMobile.openMapView" className={css.mapIconText} />
            </div>
          ) : null} */}
        </div>

        {/* {noResultsInfo ? noResultsInfo : null} */}

        <ModalInMobile
          id="SearchFiltersMobile.filters"
          containerClassName={css.modalContainer}
          isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          closeButtonMessage={modalCloseButtonMessage}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.modalHeadingWrapper}>
            <span className={css.modalHeading}>{filtersHeading}</span>
            <button
              onClick={e => this.resetAll(e)}
              className={css.mobileResetButton}
              disabled={selectedFiltersCount === 0}
            >
              <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
            </button>
          </div>

          {this.state.isFiltersOpenOnMobile ? (
            <div className={css.filtersWrapper}>{children}</div>
          ) : null}

          <div className={css.showListingsContainer}>
            <Button className={css.showListingsButton} onClick={this.closeFilters}>
              {showListingsLabel}
            </Button>
          </div>
        </ModalInMobile>
      </div>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sortByComponent: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
  isMapVariant: true,
  onMapIconClick: () => {},
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  sortByComponent: node,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,
  isMapVariant: bool,

  // from useIntl
  intl: intlShape.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = props => {
  const intl = useIntl();
  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();

  return (
    <SearchFiltersMobileComponent
      routeConfiguration={routeConfiguration}
      history={history}
      intl={intl}
      {...props}
    />
  );
};

export default SearchFiltersMobile;
